import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { fubon_store } from '~/pages/fubon/fubon_store';
import { hideIndictor } from '~/trades/indicators/fubon/hideIndictor';
export const lrc0 = createIndicator({
    id: 'fubon-lrc0',
    displayName: '成本回歸均值',
    constructorScope: {
        init() {
            return;
        },
        main(context, inputCallback) {
            const kbarTime = this.PineJS.Std.time(this._context);
            const kbarInfo = context.symbol;
            // 免費版不顯示近期的指標
            if (hideIndictor(kbarTime, kbarInfo))
                return;
            this._context = context;
            this._input = inputCallback;
            const PineJS = this.PineJS;
            const n = 240; /*this._input(0)*/
            const c = PineJS.Std.close(this._context);
            const vc = this._context.new_var(c);
            const cost240Value = PineJS.Std.linreg(vc, n, 0);
            const cost60Value = PineJS.Std.linreg(vc, 60, 0);
            fubon_store.cost240Value = cost240Value;
            fubon_store.cost60Value = cost60Value;
            return [cost240Value];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: false,
        is_price_study: !0,
        scriptIdPart: '',
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 10,
                    visible: !0,
                    color: '#ff0000',
                },
            },
            precision: 2,
            inputs: {
            /*in_0: 20*/
            },
        },
        plots: [
            { id: 'plot_0', type: 'line' },
            // { id: 'plot_1', type: 'line' },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
                linestyle: 0,
                linewidth: 3,
                plottype: 2,
                trackPrice: !1,
                transparency: 10,
                visible: !0,
                color: '#ff0000',
            },
        },
        inputs: [],
        precision: 2,
    },
});
