import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import dayAPI from '~/utils/dayAPI';
import { fubon_store } from '../fubon_store';
const CirclePie = memo(function CirclePie(props) {
    const theme = useThemeStore(t => t.theme);
    return (<div css={css `
        ${fill_vertical_all_center};
        width: 100px;
        height: 100px;
        position: relative;
      `}>
      <div className={theme} css={css `
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: double ${12}px transparent;
          background-origin: border-box;
          background-clip: content-box, border-box;
          &.dark {
            border-bottom: ${12}px solid #212229;
            background-image: linear-gradient(${'#212229'}, ${'#212229'}),
              conic-gradient(from -180deg at 50% 30%, #10ad0c, #bababa, #ff0707);
          }
          &.light {
            border-bottom: ${12}px solid #f5f5f5;
            background-image: linear-gradient(${'#efefef'}, ${'#efefef'}),
              conic-gradient(from -180deg at 50% 30%, #356d1c, #cccccc, #d42323);
          }
        `}/>
      <div className={theme} css={css `
          position: absolute;
          width: 100%;
          height: 40%;
          margin-top: -40%;

          clip-path: polygon(50% 0, 50% 0, 52% 100%, 48% 100%);
          transform-origin: bottom center;
          animation: rotate 2s ease-in-out;
          z-index: 300;
          transform: rotate(${props.score}deg);
          transition: 1s;
          z-index: 100;
          &.dark {
            background-color: #cacaca !important;
            box-shadow: 0 0 4px 2px #232323;
          }
          &.light {
            background-color: #454545 !important;
            box-shadow: 0 0 4px 2px #565656;
          }
        `}/>
      <div className={theme} css={css `
          position: absolute;
          width: 72%;
          height: 72%;
          border-radius: 50%;
          z-index: 99;
          &.dark {
            background-color: #2f2f39;
            box-shadow: 0 0 6px 4px #252525;
          }
          &.light {
            background-color: #f3f3f3;
            box-shadow: 0 0 4px 2px #b4b2b2;
          }
        `}/>
      <div className={theme} css={css `
          position: absolute;
          top: 46%;
          right: 46%;
          width: 8%;
          height: 8%;
          border-radius: 50%;
          transform-origin: bottom center;
          z-index: 300;
          &.dark {
            box-shadow: 0 0 4px 2px #232323;
            background-color: #575d60 !important;
          }
          &.light {
            box-shadow: 0 0 4px 2px #cacaca;
            background-color: #fafafa !important;
          }
        `}/>
    </div>);
});
//返回盤勢狀態文字顯示
const trendState = (closeValue, costValue, levelValue, marketPosition) => {
    const levelHigh = 75;
    const levelLow = 25;
    // 多方趨勢
    const longStrongTrend = marketPosition > 0 && levelValue >= levelHigh;
    // 偏多震盪
    const longWeakTrend = marketPosition > 0 && levelValue < levelHigh && levelValue >= levelLow;
    // 低檔盤整
    const longRestTrend = marketPosition > 0 && levelValue < levelLow;
    //空方趨勢
    const shortStrongTrend = marketPosition < 0 && levelValue <= levelLow;
    //偏空震盪
    const shortWeakTrend = marketPosition < 0 && levelValue > levelLow && levelValue <= levelHigh;
    // 低檔盤整
    const shortRestTrend = marketPosition < 0 && levelValue > levelHigh;
    const scoreAdjustment = closeValue > costValue ? 10 : -10;
    if (longStrongTrend) {
        return { label: '多方趨勢', score: 100 + scoreAdjustment, color: '#d42323' };
    }
    else if (longWeakTrend) {
        return { label: '偏多震盪', score: 50 + scoreAdjustment, color: '#d42323' };
    }
    else if (longRestTrend) {
        return { label: '低檔轉強', score: 10 + scoreAdjustment, color: '#d42323' };
    }
    else if (shortStrongTrend) {
        return { label: '空方趨勢', score: -100 + scoreAdjustment, color: '#176f00' };
    }
    else if (shortWeakTrend) {
        return { label: '偏空震盪', score: -50 + scoreAdjustment, color: '#176f00' };
    }
    else if (shortRestTrend) {
        return { label: '高檔轉弱', score: -10 + scoreAdjustment, color: '#176f00' };
    }
    else
        return { label: '盤整', score: 0, color: '#9a9a9a' };
};
export const PositionContent = memo(function PositionContent(props) {
    const entryPrice1 = 0;
    const entryUnixTime1 = 0;
    const marketPosition1 = 0;
    const entryPrice2 = 0;
    const entryUnixTime2 = 0;
    const marketPosition2 = 0;
    const nowClose = 0;
    const entryPrice = props.type === 1 ? entryPrice1 : entryPrice2;
    const entryUnixTime = props.type === 1 ? entryUnixTime1 : entryUnixTime2;
    const marketPosition = props.type === 1 ? marketPosition1 : marketPosition2;
    const openProfit = nowClose === 0 ? 0 : (nowClose - entryPrice) * marketPosition;
    const position = '---';
    const profitSymbol = openProfit > 0 ? '+' : '';
    //趨勢表邏輯
    const cost240Value = useSnapshot(fubon_store).cost240Value;
    const cost60Value = useSnapshot(fubon_store).cost60Value;
    const costValue = props.type === 1 ? cost240Value : cost60Value;
    const levelValue = useSnapshot(fubon_store).levelValue;
    const trendStatus = trendState(nowClose, costValue, levelValue, marketPosition);
    const theme = useThemeStore(t => t.theme);
    return (<styleds.container className={theme}>
      <styleds.trendContent className={theme}>
        <CirclePie score={trendStatus.score.toFixed(0)}/>
        <styleds.trendString color={trendStatus.color}>{trendStatus.label}</styleds.trendString>
      </styleds.trendContent>
      <styleds.positionContent>
        <styleds.item>
          <span>進場日期: </span>
          <span>{dayAPI(entryUnixTime).format('MM/DD')}</span>
        </styleds.item>
        <styleds.item>
          <span>進場時間: </span>
          <span>{dayAPI(entryUnixTime).format('HH:mm')}</span>
        </styleds.item>
        <styleds.item>
          <span>部位方向: </span>
          <div>
            <styleds.positionValue position={marketPosition} className={theme}>
              {position}
            </styleds.positionValue>
          </div>
        </styleds.item>
        <styleds.item>
          <span>進場成本: </span>
          <span>{entryPrice}</span>
        </styleds.item>
        <styleds.item>
          <span>浮動點數: </span>
          <styleds.openProfitValue value={openProfit} className={theme}>
            {profitSymbol}
            {openProfit.toFixed(2)}
          </styleds.openProfitValue>
        </styleds.item>
      </styleds.positionContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    display: grid;
    grid-template-columns: 148px calc(100% - 148px);
    width: 100%;
    height: 148px;
    padding: 4px;
    gap: 8px;
    font-size: 14px;
    border-radius: 4px;
    &.dark {
      color: #fafafa;
      background-color: #17181d;
    }
    &.light {
      color: #252525;
      background-color: #fafafa;
    }
  `,
    trendContent: styled.div `
    ${fill_vertical_all_center};
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    &.dark {
      background-color: #212229;
    }
    &.light {
      background-color: #f5f5f5;
    }
  `,
    trendString: styled.div `
    ${fill_horizontal_all_center};
    height: 24px;
    width: 72px;
    border-radius: 2px;
    padding: 2px 4px;
    color: #fafafa;
    background: linear-gradient(45deg, ${props => props.color}, ${props => props.color}aa);
  `,
    positionContent: styled.div `
    ${fill_vertical_cross_center};
    ${jc.spaceAround};
    align-items: start;
  `,
    item: styled.div `
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100%;
    gap: 8px;
  `,
    positionValue: styled.span `
    &.dark {
      background-color: ${props => props.position === 1 ? '#d42323' : props.position === -1 ? '#176f00' : '#cccccc'};
    }
    &.light {
      color: #ffffff;
      background-color: ${props => props.position === 1 ? '#cc0000' : props.position === -1 ? '#00aa00' : '#cccccc'};
    }
    padding: 1px 6px;
    border-radius: 2px;
  `,
    openProfitValue: styled.span `
    &.dark {
      color: ${props => (props.value > 0 ? '#ff3a3a' : props.value < 0 ? '#00ff00' : '#cccccc')};
    }
    &.light {
      color: ${props => (props.value > 0 ? '#cc0000' : props.value < 0 ? '#176f00' : '#cccccc')};
    }
  `,
};
