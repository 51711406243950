import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { fubon_store } from '~/pages/fubon/fubon_store';
import { hideIndictor } from '~/trades/indicators/fubon/hideIndictor';
export const wr0 = createIndicator({
    id: 'wr0',
    displayName: '位階指標',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const kbarTime = this.PineJS.Std.time(this._context);
            const kbarInfo = context.symbol;
            // 免費版不顯示近期的指標
            if (hideIndictor(kbarTime, kbarInfo))
                return;
            const f_0 = function (e, t, i) {
                return 100 + (100 * (e - t)) / (t - i);
            };
            const i = 120;
            const o = this.PineJS.Std.high(this._context);
            const r = this._context.new_var(o);
            const s = this.PineJS.Std.highest(r, i, this._context);
            const a = this.PineJS.Std.low(this._context);
            const l = this._context.new_var(a);
            const c = this.PineJS.Std.lowest(l, i, this._context);
            const close = this.PineJS.Std.close(this._context);
            fubon_store.levelValue = f_0(close, s, c);
            return [f_0(close, s, c)];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        scriptIdPart: '',
        is_price_study: !1,
        is_hidden_study: !1,
        isCustomIndicator: true,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 25,
                    visible: !0,
                    color: '#fbc02d',
                },
            },
            precision: 2,
            bands: [
                {
                    color: '#dd0000',
                    linestyle: 1,
                    linewidth: 2,
                    visible: !0,
                    value: 75,
                },
                {
                    color: '#0ca74a',
                    linestyle: 1,
                    linewidth: 2,
                    visible: !0,
                    value: 25,
                },
            ],
            filledAreasStyle: {
                fill_0: {
                    color: '#000080',
                    transparency: 100,
                    visible: !0,
                },
            },
            inputs: {},
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        bands: [
            {
                id: 'hline_0',
                name: 'UpperLimit',
            },
            {
                id: 'hline_1',
                name: 'LowerLimit',
            },
        ],
        filledAreas: [
            {
                id: 'fill_0',
                objAId: 'hline_0',
                objBId: 'hline_1',
                type: 'hline_hline',
                title: 'Hlines Background',
            },
        ],
        inputs: [],
    },
});
