export function hideIndictor(kbarTime, kbarInfo) {
    const oneDay = 24 * 60 * 60 * 1000;
    //   console.log('kbarInfo.isLastBar', kbarInfo.isLastBar)
    //  60分k以下為數字，60分k以上為字串
    if (kbarInfo.resolution === 'D') {
        return kbarTime > new Date().getTime() - 3 * oneDay;
    }
    else if (kbarInfo.resolution === 'W') {
        return kbarTime > new Date().getTime() - 7 * oneDay;
    }
    else if (kbarInfo.resolution === 'M') {
        return kbarTime > new Date().getTime() - 32 * oneDay;
    }
    else if (parseInt(kbarInfo.period) >= 60) {
        return kbarTime > new Date().getTime() - 3 * oneDay;
    }
    else {
        return kbarTime > new Date().getTime() - oneDay;
    }
    //   if (parseInt(kbarInfo.interval) <= 60) {
    //     return kbarTime > new Date().getTime() - oneDay
    //   } else if (kbarInfo.interval === '1D') {
    //     return kbarTime > new Date().getTime() - 5 * oneDay
    //   } else {
    //     return !kbarInfo.isBarClosed
    //   }
    //   const timeLimit = selectTimeLimit(interval)
    //   return kbarTime > timeLimit
}
// function selectTimeLimit(interval: string) {
//   const oneDay = 24 * 60 * 60 * 1000
//   //  60分k以下為數字，60分k以上為字串
//   if (parseInt(interval) <= 60) {
//     return new Date().getTime() - oneDay
//   } else if (interval === '1D') {
//     return new Date().getTime() - 5 * oneDay
//   } else {
//     return new Date().getTime() - 8 * oneDay
//   }
// }
